<template>
  <b-modal
    id="modal__BehaviourTreeDelete"
    title="Delete Behaviour Tree. Are you absolutely sure?"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Yes, delete behaviour tree'"
    :ok-disabled="loading"
    ok-variant="danger"
    cancel-title="Keep"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="onShow"
    @close="onClose"
    @cancel="onClose"
    @ok.prevent="onSubmit"
  >
    <b-alert
      show
      variant="danger"
    >
      <div class="alert-body d-inline-flex justify-content-between">
        <div class="mr-1">
          <feather-icon
            icon="AlertCircleIcon"
            class="text-white"
            size="30"
          />
        </div>
        <div>
          <p>
            You're about to permanently delete the behaviour tree
            <span class="font-weight-bolder">{{ selectedBTObj.name }}</span>
            <!--and-->
            <!--<span class="font-weight-bolder">all of its behaviour nodes</span>.-->
          </p>
          <p>
            To avoid unintended data loss, consider reviewing this action before deleting it.
          </p>
        </div>
      </div>
    </b-alert>
    <div>
      <b-table
        responsive="sm"
        :fields="tableFields"
        :items="tableData"
        hover
      />
    </div>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'DeleteBehaviourTreeModal',
  setup(props, context) {
    const loading = ref(false)
    const selectedBTObj = ref({})
    const tableFields = [
      {
        key: 'property', label: 'Property', thStyle: 'width: 10rem;', tdClass: 'font-small-3 text-muted',
      },
      { key: 'value', label: 'Value', tdClass: 'font-small-3' },
    ]

    const tableData = ref([])
    watch(selectedBTObj, val => {
      tableData.value = [
        { property: 'ID', value: selectedBTObj.value.id },
        { property: 'Name', value: selectedBTObj.value.name },
        { property: 'Description', value: selectedBTObj.value.description },
        { property: 'Created By', value: `${this.getUserUsername(selectedBTObj.value.created_by)} at ${selectedBTObj.value.created}` },
        { property: 'Updated By', value: `${this.getUserUsername(selectedBTObj.value.updated_by)} at ${selectedBTObj.value.updated}` },
      ]
    })

    const onShow = () => {
      selectedBTObj.value = store.state.behaviourTrees.selectedBehaviourTreeObj
    }
    const onClose = () => {
      store.dispatch('behaviourTrees/clearSelectedBehaviourTree')
    }

    const onSubmit = () => {
      loading.value = true
      store
        .dispatch('behaviourTrees/deleteBehaviourTree')
        .then(success => {
          if (success) {
            context.root.$bvModal.hide('modal__BehaviourTreeDelete')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      selectedBTObj,
      tableFields,
      tableData,
      onShow,
      onClose,
      onSubmit,
    }
  },
}
</script>
