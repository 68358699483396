<template>
  <b-modal
    id="modal__BehaviourTreeUpdate"
    title="Update Behaviour Tree"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Update Behaviour Tree'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="onShow"
    @close="onClose"
    @cancel="onClose"
    @ok.prevent="onSubmit"
  >
    <b-form-group
      label="Name"
      label-for="bt-name"
    >
      <b-form-input
        id="bt-name"
        v-model="formFields.name"
        placeholder="Behaviour Tree name..."
      />
    </b-form-group>

    <b-form-group
      label="Description"
      label-for="bt-description"
    >
      <b-textarea
        id="bt-description"
        v-model="formFields.description"
        placeholder="Describe the Behaviour Tree..."
        rows="5"
      />
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          label="Status"
          label-for="bt-status"
        >
          <b-form-select
            id="bt-status"
            v-model="formFields.status"
            :options="statusOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Tag"
          label-for="bt-tag"
        >
          <b-form-input
            id="bt-tag"
            v-model="formFields.tag"
            placeholder="Enter a single tag..."
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="w-100 d-flex justify-content-start">
      <span class="font-small-2 text-muted">
        UUID: <span class="select-all">{{ selectedBehaviourTreeObj.id }}</span>
      </span>
    </div>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'UpdateBehaviourTreeModal',
  setup(props, context) {
    const loading = ref(false)
    const formFields = ref({
      name: '',
      description: '',
      status: '',
      tag: '',
    })
    const statusOptions = [
      { value: 'Draft', text: 'Draft' },
      { value: 'Review', text: 'Review' },
      { value: 'Published', text: 'Published' },
    ]

    const selectedBehaviourTreeObj = ref({})
    const onShow = () => {
      selectedBehaviourTreeObj.value = store.state.behaviourTrees.selectedBehaviourTreeObj
      const {
        name, description, status, tag,
      } = selectedBehaviourTreeObj.value
      // Set view to API values
      formFields.value = {
        name, description, status, tag,
      }
    }
    const onClose = () => {
      store.dispatch('behaviourTrees/clearSelectedBehaviourTree')
      formFields.value = {
        name: '', description: '', status: '', tag: '',
      }
    }
    const onSubmit = () => {
      loading.value = true
      store
        .dispatch('behaviourTrees/updateBehaviourTree', formFields.value)
        .then(data => {
          if (data) {
            context.root.$bvModal.hide('modal__BehaviourTreeUpdate')
          }
        })
        .finally(() => { loading.value = false })
    }

    return {
      loading,
      formFields,
      selectedBehaviourTreeObj,
      statusOptions,
      onShow,
      onClose,
      onSubmit,
    }
  },
}
</script>
