<template>
  <b-modal
    id="modal__BehaviourTreeCopy"
    title="Copy Behaviour Tree"
    size="lg"
    lazy
    no-fade
    @show="onShow"
    @close="onClose"
    @cancel="onClose"
    @ok.prevent="onSubmit"
  >
    <b-form-group
      label="Behaviour Tree name"
      label-for="behaviour-tree-name"
    >
      <b-form-input
        id="behaviour-tree-name"
        v-model="formFields.name"
        placeholder="Copy of ..."
        required
        @input="val => $emit('input', { ...value, ...{ prefix: val } })"
      />
    </b-form-group>

    <b-form-group
      label="Description"
      label-for="behaviour-tree-description"
    >
      <b-textarea
        id="behaviour-tree-description"
        v-model="formFields.description"
        placeholder="Give a brief description of the Behaviour Tree..."
        rows="5"
      />
    </b-form-group>

    <div class="w-100 d-inline-flex justify-content-around">
      <b-form-checkbox
        id="behaviour-tree-include-requirements"
        v-model="formFields.withReqs"
        :checked="formFields.withReqs"
        name="reqs-checkbox"
        class="custom-control-info"
        switch
      >
        Include Requirements
      </b-form-checkbox>
      <b-form-checkbox
        id="behaviour-tree-include-issues"
        v-model="formFields.withIssues"
        :checked="formFields.withIssues"
        name="iss-checkbox"
        class="custom-control-info"
        switch
      >
        Include Issues
      </b-form-checkbox>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <div v-if="loading" class="font-small-2">
        Copying may take a few minutes...
      </div>
      <div>
        <b-button variant="outline-secondary" class="mr-50" @click="cancel()">
          Dismiss
        </b-button>
        <b-button variant="success" :disabled="loading" @click="ok()">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Copying...
          </span>
          <span v-else>
            Create Copy
          </span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'CopyBehaviourTreeModal',
  setup(props, context) {
    const loading = ref(false)
    const formFields = ref({
      name: '',
      description: '',
      withReqs: false,
      withIssues: false,
    })
    const selectedBehaviourTreeObj = ref({})

    const onShow = () => {
      selectedBehaviourTreeObj.value = store.state.behaviourTrees.selectedBehaviourTreeObj
      const { name, description } = selectedBehaviourTreeObj.value
      formFields.value = {
        name: `Copy of ${name}`,
        description,
        withReqs: true,
        withIssues: true,
      }
    }
    const onClose = () => {
      store.dispatch('behaviourTrees/clearSelectedBehaviourTree')
    }
    const onSubmit = () => {
      loading.value = true
      store
        .dispatch('behaviourTrees/copyBehaviourTree', formFields.value)
        .then(data => {
          if (data) {
            context.root.$bvModal.hide('modal__BehaviourTreeCopy')
          }
        })
        .finally(() => { loading.value = false })
    }

    return {
      loading,
      formFields,
      selectedBehaviourTreeObj,
      onShow,
      onClose,
      onSubmit,
    }
  },
}
</script>
